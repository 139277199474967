<template>
  <div>
    <div class="mb-5">
      <v-autocomplete
        class="c-input-small"
        v-model="reason"
        :items="reasonOptions"
        :placeholder="$t('labels.reason')"
        :label="$t('labels.reason')"
        ref="reasonRef"
        @change="onReasonChanged"
        dense
        outlined
        hide-details
      ></v-autocomplete>
    </div>

    <div class="mb-5" v-if="isRequireNote">
      <v-textarea
        class="c-input-small"
        v-model="note"
        :placeholder="$t('labels.note')"
        :label="$t('labels.note')"
        :single-line="false"
        :rows="2"
        ref="noteRef"
        dense
        outlined
        clearable
        hide-details
      ></v-textarea>
    </div>

    <div class="mb-5">
      <input-qr-scan-model
        v-model="code"
        ref="codeRef"
        :disabled="isDisabledCodeInput"
        :label="$t('labels.uid_sku_barcode')"
        @keyupEnter="scanInput"
      ></input-qr-scan-model>
    </div>
  </div>
</template>

<script>
import {
  downloadExcelFile,
  formatDateTime,
  isMobile,
  hideString,
} from "@/libs/helpers";
import { httpClient } from "@/libs/http";
import { REASON_TYPE_EMPLOYEE_EXPORT_ITEM } from "@/libs/const";

export default {
  name: "EmployeeExport",
  components: {},
  data: () => ({
    isLoading: false,
    code: null,
    note: null,
    reason: null,
    reasons: [],
    items: [],
  }),
  computed: {
    reasonOptions() {
      return [...this.reasons].map((r) => ({
        text: r.description,
        value: r.id,
      }));
    },
    isDisabledCodeInput() {
      return !this.reason || (this.isRequireNote && !this.note);
    },
    isRequireNote() {
      if (!this.reason) {
        return false;
      }

      const selected = [...this.reasons].find((r) => r.id == this.reason);
      return !!selected.is_require_note;
    },
  },
  mounted() {
    this.getReasons();
  },
  methods: {
    downloadExcelFile,
    formatDateTime,
    isMobile,
    hideString,
    onReasonChanged() {
      this.$refs.reasonRef.blur();
      setTimeout(() => {
        if (!this.isRequireNote) {
          this.$refs.codeRef.focusInput();
        } else {
          this.$refs.noteRef.focus();
        }
      }, 500);
    },
    getReasons() {
      httpClient
        .post("/common/v1/get-reason", {
          type: REASON_TYPE_EMPLOYEE_EXPORT_ITEM,
        })
        .then(({ data }) => {
          this.reasons = [...data];
        });
    },
    async scanInput() {
      if (!this.code) {
        return false;
      }

      try {
        await httpClient.post("/goods-issue-detail/v1/employee-export-order", {
          code: this.code,
        });

        this.$vToastify.success(this.$t("messages.create_success"));
        this.code = null;
        this.isLoading = false;
        document.getElementById("success_sound_player").play();
        return false;
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        document.getElementById("error_sound_player").play();
      }
    },
  },
};
</script>

<style scoped></style>
